import React from "react"
import Layout from "../containers/Layout"
import BoxCollection from "../containers/BoxCollection"
import Box from "../components/Box"
import boxColors from "../styles/box-colors.module.scss"

export default () => (
  <Layout>
    <h2>Datenschutzerklärung</h2>
    <p>
      Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    <p>
      Leonard Follner
      <br />
      Julius-Scholtz-Straße 52
      <br />
      01217 Dresden
    </p>
    <h3>Ihre Betroffenenrechte</h3>
    <p>
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
    </p>
    <ul>
      <li>
        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,
      </li>
      <li>Berichtigung unrichtiger personenbezogener Daten,</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
      <li>
        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
        gesetzlicher Pflichten noch nicht löschen dürfen,
      </li>
      <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
      <li>
        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
        haben oder einen Vertrag mit uns abgeschlossen haben.
      </li>
    </ul>
    <p>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige
      Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich
      nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen
      Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
      Bereich) mit Anschrift finden Sie unter:{" "}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="noopener"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>
    <h3>
      Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte
    </h3>
    <p>
      Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
      Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
      persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet
      nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
    </p>
    <ul>
      <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
      <li>
        die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich
        ist,
      </li>
      <li>
        die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
        erforderlich ist,
      </li>
    </ul>
    <p>
      die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und
      kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
      Interesse an der Nichtweitergabe Ihrer Daten haben.
    </p>
    <h3>Cookies</h3>
    <p>
      Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
      Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre
      Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte
      Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre
      Verbindung zum Internet.
    </p>
    <p>
      Cookies können nicht verwendet werden, um Programme zu starten oder Viren
      auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen
      Informationen können wir Ihnen die Navigation erleichtern und die korrekte
      Anzeige unserer Webseiten ermöglichen.
    </p>
    <p>
      In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben
      oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten
      hergestellt.
    </p>
    <p>
      Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
      betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
      Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies
      jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte
      verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren,
      wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass
      einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
      wenn Sie die Verwendung von Cookies deaktiviert haben.
    </p>
    <h3>Kontaktformular</h3>
    <p>
      Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit
      uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre
      freiwillige Einwilligung. Hierfür ist die Angabe einer validen
      E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der
      anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist
      optional. Die von Ihnen gemachten Angaben werden zum Zwecke der
      Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
      Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene
      Daten automatisch gelöscht.
    </p>
    <h3>
      <strong>Änderung unserer Datenschutzbestimmungen</strong>
    </h3>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
    </p>
    <h3>
      <strong>Fragen an den Datenschutzbeauftragten</strong>
    </h3>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an die für den Datenschutz verantwortliche
      Person in unserer Organisation:
    </p>
    <p>Leonard Follner</p>
    <p>Julius-Scholtz-Straße 52</p>
    <p>01217 Dresden</p>
    <p>leonard.follner@gmail.com</p>
  </Layout>
)
